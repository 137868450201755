import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import innertext from "innertext"
import { createAbsoluteLink } from "../../utils"

function SEO({ description, lang, meta, keywords, title, image, seo }) {
  // console.log(seo)
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const title = seo?.title
        const metaDescription = innertext(
          seo?.metaDesc || data.site.siteMetadata.description
        )
        let seoImage =
          seo?.opengraphImage?.localFile?.publicURL ||
          data?.site?.siteMetadata?.image

        seoImage = createAbsoluteLink(seoImage)

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            // titleTemplate={innertext(`%s | ${data.site.siteMetadata.title}`)}
            meta={[
               {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `author`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `title`,
                property: `og:title`,
                content: title,
              },
              {
                name: `description`,
                property: `og:description`,
                content: metaDescription,
              },
              {
                name: `image`,
                property: `og:image`,
                content: seoImage,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
             
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                property: `og:image`,
                content: seoImage,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  image: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  image: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        image
      }
    }
  }
`

export const WpYoastSEO = graphql`
  fragment WpYoastSEO on WpPostTypeSEO {
    title
    metaDesc
    metaKeywords
    focuskw
    opengraphImage {
      sourceUrl
      localFile {
        publicURL
      }
    }
  }
`
