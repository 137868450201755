import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import BootstrapModal from "../Bootstrap/Modal"
import Location from "../Map/location"

export const featuredImage = graphql`
  fragment featuredImage on WpMediaItem {
    __typename
    altText
    sourceUrl
    id
    modified
    description
    localFile {
      extension
      publicURL
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const FeaturedImage = ({ image, classes, page }) => {
  // console.log(image.localFile.extension);
  // console.log(image)
  return (
    <div className={`visual ${classes ? classes : ""}`}>
      {image && (
        <div className="img-overview">
          {image && image.localFile.childImageSharp && (
            <Img
              alt={image.altText || "Digital Exactly"}
              fluid={image.localFile.childImageSharp.fluid}
            />
          )}
          {image && !image.localFile.childImageSharp && (
            <img
              src={image.localFile.publicURL}
              className="img-responsive"
              alt={image.altText}
            />
          )}

          {image?.description && (
            <>
              <div
                className="highlights"
                dangerouslySetInnerHTML={{ __html: image.description }}
              ></div>
              <span className="dot"></span>
            </>
          )}
        </div>
      )}

      {page && page === "contact-us" && <Location />}

      {page && page === "home" && (
        <BootstrapModal videoLink="https://www.youtube.com/embed/_64FWlXTYGc?start=0&rel=0&mute=0&autoplay=1&cc_load_policy=0&enablejsapi=1" />
      )}

      {page && page === "our-services" && (
        <BootstrapModal videoLink="https://www.youtube.com/embed/JmC0xkCMFCE?start=0&rel=0&mute=0&autoplay=1&cc_load_policy=0&enablejsapi=1" />
      )}
    </div>
  )
}

export default FeaturedImage
