import React from "react"
import { Button, Modal } from "react-bootstrap"

export default class BootstrapModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
  }

  openModal = () => {
    const { modal } = this.state
    if (!modal) {
      this.setState({ modal: true })
    }
  }

  closeModal = () => {
    const { modal } = this.state
    if (modal) {
      this.setState({ modal: false })
    }
  }

  render = () => {
    const { videoLink } = this.props

    // console.log(videoLink);

    return (
      <>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modal}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="entry-content-asset">
              <iframe
                title="PWA"
                width="800"
                height="400"
                src={videoLink}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Modal.Body>
        </Modal>
        <Button
          onClick={this.openModal}
          aria-label="See PWA Success Stories"
          className={`btn btn-play ${this.state.modal ? "d-none" : ""}`}
        ></Button>
      </>
    )
  }
}
