export default {
  mapStyle: [
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#f9f9ff",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#01d85f",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#01d85f",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#013d93",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};
