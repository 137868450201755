import React from "react"
import FooterMenu from "../Menu/footer"

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="row">
          <div className="col-md-12" id="copyright">
            <FooterMenu location={this.props.location} />
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
