import React from "react"
import { Link } from "gatsby"

import SiteMenu from "../Menu"

// const renderLoader = () => <p>Loading...</p>;
// const SiteMenu = lazy(() => import("../Menu"));

class Header extends React.Component {
  render() {
    return (
      <header>
        <nav className="navbar navbar-expand-md">
          <Link className="navbar-brand" to="/">
            <img
              src={"/images/Digital Exactly.svg"}
              width="100"
              height="117"
              alt="Digital Exactly"
            />
          </Link>
          <SiteMenu location={this.props.location} />
        </nav>
      </header>
    )
  }
}

export default Header
