import React from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";

import googleMapStyles from "./GoogleMapStyles";

export class Maps extends React.Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    // console.log(this.props.mapStyle);
    return (
      <Map
        google={this.props.google}
        mapTypeControl={false}
        zoom={11}
        styles={this.props.mapStyle}
        initialCenter={{ lat: 25.1882446, lng: 55.2715883 }}
      >
        <Marker
          position={{ lat: 25.1882446, lng: 55.2715883 }}
          onClick={this.onMarkerClick}
          name={"Digital Exactly"}
        />
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
        >
          <div>
            
            <p>
              Office 2701, Prime Tower, 
              <br />
              Business Bay, Dubai
              <br />
              United Arab Emirates
            </p>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

Maps.defaultProps = googleMapStyles;

export default GoogleApiWrapper({
  apiKey: "AIzaSyDq-TbJta8RSlG9JZnJ6Vb0ZGAJ2AFCKT8",
})(Maps);
