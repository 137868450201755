import React from "react"
// import Img from "gatsby-image";

import SEO from "../components/SEO"

import Header from "../components/Header"
import Footer from "../components/Footer"
import FeaturedImage from "../components/FeaturedImage"

// const renderLoader = () => <p>Loading</p>;
// const Header = lazy(() => import("../components/Header"));
// const Footer = lazy(() => import("../components/Footer"));

const Layout = ({
  children,
  location,
  cssClass,
  featuredImage,
  title,
  description,
  seo,
}) => {
  // console.log(location.pathname);
  return (
    <>
      <SEO title={title} description={description} seo={seo} />
      <div className={`container ${cssClass}`}>
        <div className="row main no-gutters">
          <div id="content" className="col-lg-7">
            <div id="main">
              <Header location={location} />
              {children}
              <Footer />
            </div>
          </div>
          <FeaturedImage
            image={featuredImage}
            page={cssClass}
            classes="desktop"
          />
        </div>
      </div>
    </>
  )
}

export default Layout
